import React, { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { userRouterData } from '@/store/router.js';

const NavWrap = styled.div`
  height: 50px;
  position: relative;
  z-index: 10;
  background: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px 1px rgba(120, 150, 183, 0.1);
  padding: 0 20px;
`;

function CommonNav() {
  const location = useLocation();
  const params = useParams();

  const { routeList } = userRouterData();
  const data = useMemo(() => {
    if (location.pathname === '/home') {
      return [
        {
          title: '首页'
        }
      ];
    } else {
      let path = location.pathname;
      if (params.id) {
        path = path.replace(new RegExp('/' + params.id), '/:id');
      }

      let current = routeList.find((item) => item.path === path);
      let result = createNavList(current);
      if (!result) {
        return;
      }
      result = result.filter(Boolean);
      return result.map((item, index) => {
        if (index === result.length - 1) {
          return {
            ...item,
            path: undefined
          };
        }
        return item;
      });
    }
  }, [location.pathname, routeList]);

  function createNavList(data) {
    if (!data) {
      return;
    }
    //
    let result = [];

    result.unshift({
      title: data.meta.title ? data.meta.title : '',
      path: data.meta.parent ? data.meta.parent : data.path
    });
    if (data.meta.parent && routeList.find((item) => item.path === data.meta.parent)) {
      let parent = routeList.find((item) => item.path === data.meta.parent);

      result = [...createNavList(parent), ...result];
    } else {
      result = [
        {
          title: '首页',
          path: '/home'
        },
        ...result
      ];
    }
    return result;
  }

  //格式化路径路径
  function itemRender(route) {
    return route.path ? <a href={route.path}>{route.title}</a> : <span>{route.title}</span>;
  }
  return (
    <NavWrap>
      <Breadcrumb separator=">" items={data} itemRender={itemRender} />
    </NavWrap>
  );
}

export default CommonNav;
