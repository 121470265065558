import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
const LoadingWrap = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
`;

export default function Loading() {
  return (
    <LoadingWrap>
      <Spin />
    </LoadingWrap>
  );
}
