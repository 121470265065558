import axios from 'axios';
import { getToken } from '@/utils/token';
import { useCommonData } from '@/store/common.js';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
// import useGlobalStore from '@/store/modules/antd.ts';

const contentTypeList = ['application/json', 'application/x-www-form-urlencoded;charset=UTF-8', 'multipart/form-data'];
const defaultContentType = contentTypeList[0];

class Request {
  static iNow = 0;
  service;

  static defaultSetting = {
    timeout: 0,
    headers: {
      'Content-Type': defaultContentType
    },
    loading: false
  };
  constructor(config) {
    Request.iNow = 0;
    config = Object.assign(Request.defaultSetting, config);
    this.service = axios.create(config);

    this.init();
  }
  init() {
    this.service.interceptors.request.use((config) => {
      const token = getToken();

      if (config.loading) {
        Request.iNow += 1;

        if (Request.iNow >= 1) {
          useCommonData.getState().setLoading(true);
        }
      }
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    });
    this.service.interceptors.response.use(
      (response) => {
        // const { setLoading } = useCommonData();
        if (response.config.loading) {
          Request.iNow -= 1;
        }
        if (Request.iNow <= 0) {
          useCommonData.getState().setLoading(false);
        }
        if ((response.config.responseType && response.config.responseType === 'blob') || response.config.custom) {
          return response.data;
        }
        if (response.status === 200) {
          if (response.data.code === 200) {
            return response.data;
          } else {
            if (window.message) {
              message.error(response.data.message);
            } else {
              message.error(response.data.message);
            }

            if (response.data.code === 400) {
              let url = window.location.href;
              window.location.href = '/login?url=' + url;
            }
            return Promise.reject(response.data);
          }
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        useCommonData.getState().setLoading(false);
        if (error.response?.data?.msg) {
          message.error(error.response?.data?.msg);
        } else {
          message.error('系统故障');
        }
      }
    );
  }
  request(config) {
    return this.service(config);
  }
  post(url, data, config = { loading: true }) {
    return this.service({
      url,
      method: 'post',
      data,
      ...config
    });
  }
  get(url, params, config = { loading: true }) {
    const newConfig = {
      params,
      ...config
    };

    return this.service.get(url, newConfig);
  }
}

export default Request;
