import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';
const store = create(
  persist(
    (set, get) => ({
      userInfo: {},
      setUserInfo: (payload) => set({ userInfo: _.cloneDeep(payload) })
    }),
    {
      name: 'permissions'
    }
  )
);

export default store;
