import style from './index.module.less';
import React from 'react';
const SvgIcon = ({ name = '', className = '', color = '' }) => {
  const iconName = React.useMemo(() => {
    return name.startsWith('#icon') ? `${name}` : `#icon-${name}`;
  }, [name]);
  const svgClass = React.useMemo(() => {
    if (className) {
      return `${style.svgIcon} ${className} `;
    }
    return style.svgIcon;
  }, [className]);
  return (
    <svg className={svgClass} aria-hidden="true">
      <use xlinkHref={iconName} fill={color ? color : undefined} />
    </svg>
  );
};

export default SvgIcon;
