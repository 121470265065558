import http from '@/services';

//获取路由列表
export function getMenuTreeApi() {
  return http.get('/api/system_manage/query/user_permission_tree');
}

//获取菜单列表
export function getUserMenuApi(params) {
  return http.get('/api/system_manage/query/user_permission_tree', params);
}
//获取菜单管理菜单列表
export function getUserAllMenuApi(params) {
  return http.get('/api/system_manage/query/system_permission_tree', params);
}
//创建菜单
export function createMenuApi(data) {
  return http.post('/api/system_manage/create/system_permission', data);
}
//编辑菜单
export function updateMenuApi(data) {
  return http.post('/api/system_manage/update/system_permission', data);
}
//删除菜单
export function removeMenuApi(ids) {
  return http.post('/api/system_manage/delete/system_permission', ids);
}

//获取用户列表
export function getUserListApi(params) {
  return http.get('/api/system_manage/query/admin_user/byPage', params);
}
//创建用户
export function createUserApi(data) {
  return http.post('/api/system_manage/create/admin_user', data);
}
//删除用户
export function removeUserApi(data) {
  return http.post('/api/system_manage/delete/admin_user', data);
}
//重置用户密码
export function resetPasswordApi(data) {
  return http.post('/api/system_manage/reset/admin_user_password', data);
}
//获取用户详情
export function getUserDetailApi(id) {
  return http.get('/api/system_manage/query/admin_user/byId', {
    id
  });
}
//编辑用户
export function updateUserApi(data) {
  return http.post('/api/system_manage/update/admin_user', data);
}
//分配角色到用户
export function setUserForRoleListApi(data) {
  return http.post('/api/system_manage/allocate/admin_user_role', data);
}

//获取角色列表
export function getRoleListApi(params) {
  return http.get('/api/system_manage/query/system_role/byPage', params);
}

//创建角色
export function createRoleApi(data) {
  return http.post('/api/system_manage/create/system_role', data);
}

//获取角色详情
export function getRoleDetailApi(id) {
  return http.get('/api/system_manage/query/system_role/byId?id=' + id);
}
//编辑角色
export function updateRoleApi(data) {
  return http.post('/api/system_manage/update/system_role', data);
}

//删除角色
export function removeRolesApi(ids) {
  return http.post('/api/system_manage/delete/system_role', ids);
}

//获取字典列表(所有)
export function getDictListAllApi() {
  return http.get('/api/system_manage/query/system_dictionary_list');
}
//获取字典列表(分页)
export function getDictListApi(params) {
  return http.get('/api/system_manage/query/system_dictionary/byPage', params);
}
//创建字典类型
export function createDictApi(data) {
  return http.post('/api/system_manage/create/system_dictionary', data);
}
//编辑字典
export function updateDictApi(data) {
  return http.post('/api/system_manage/update/system_dictionary', data);
}
//删除字典
export function removeDictApi(ids) {
  return http.post('/api/system_manage/delete/system_dictionary', ids);
}
//获取字典值列表
export function getDictValueListApi(params) {
  return http.get('/api/system_manage/query/system_dictionary_data/byPage', params);
}
//创建数据字典值
export function createDictValueApi(data) {
  return http.post('/api/system_manage/create/system_dictionary_data', data);
}
//编辑数据字典值
export function updateDictValueApi(data) {
  return http.post('/api/system_manage/update/system_dictionary_data', data);
}
//删除数据字典值
export function removeDictValueApi(ids) {
  return http.post('/api/system_manage/delete/system_dictionary_data', ids);
}
//根据字典类型查询字典数据
export function getDictValueByType(type) {
  return http.get('/api/system_manage/query/system_dictionary_data/byType', { dictionaryType: type });
}

//资源分组列表
export function getResourceGroupList() {
  return http.get('/api/system_manage/query/system_resource_group_list');
}

//新建资源分组
export function createResourceGroupApi(resourceGroupName) {
  return http.post('/api/system_manage/create/system_resource_group', {
    resourceGroupName
  });
}
//编辑资源分组
export function updateResourceGroupApi(data) {
  return http.post('/api/system_manage/update/system_resource_group', data);
}
//删除资源分组
export function removeResourceGroupApi(ids) {
  return http.post('/api/system_manage/delete/system_resource_group', ids);
}

//资源-图片列表
export function getResourceImageListApi(params) {
  return http.get('/api/system_manage/query/system_resource/byPage', params);
}
//资源-创建图片
export function createResourceImageApi(data) {
  return http.post('/api/system_manage/create/system_resource', data);
}
//删除资源
export function removeResourceApi(ids) {
  return http.post('/api/system_manage/delete/system_resource', ids);
}
//移动资源
export function moveResourceApi(data) {
  return http.post('/api/system_manage/move/system_resource_group', data);
}

//根据角色id查询已授权的用户列表
export function getUserJoinUserApi(params) {
  return http.get('/api/system_manage/query/authorized_admin_user/byPage', params);
}
//根据角色id查询未授权的用户列表
export function getUserNoUserApi(params) {
  return http.get('/api/system_manage/query/unauthorized_admin_user/byPage', params);
}
//批量给多个用户授予一个角色
export function updateUserListForRoleApi(data) {
  return http.post('/api/system_manage/authorize/system_user_role', data);
}
//批量给多个用户取消一个角色
export function updateUserListCancelRoleApi(data) {
  return http.post('/api/system_manage/cancel/authorized_system_user_role', data);
}
//操作日志列表
export function getOperatorLogApi(data) {
  return http.get('/api/system_manage/query/system_operation_log/ByPage', data);
}
//操作日志详情
export function getOperatorDetailApi(id) {
  return http.get('/api/system_manage/query/system_operation_log/ById', { id });
}
//登录日志列表
export function getLoginLogApi(params) {
  return http.get('/api/system_manage/query/system_login_log/byPage', params);
}
