import { create } from 'zustand';
import { floatArray } from '@/utils/tools.js';
import { useParams } from 'react-router-dom';
import { persist } from 'zustand/middleware';
import _ from 'lodash';
import { getUserMenuApi } from '@/api/system.js';

export const userRouterData = create((set, get) => ({
  staticRoute: [
    {
      path: '/home',
      url: '../pages/home',
      meta: {
        title: '首页',
        type: 'C'
      }
    },
    {
      path: '/account-info',
      id: '/account-info',
      url: '../pages/accountInfo',
      meta: {
        title: '帐号信息',
        type: 'C',
        parent: '/home'
      }
    },

    {
      path: '/system/admin/role/:id',
      id: '/system/admin/role/:id',
      url: '../pages/system/admin/roles',
      meta: {
        title: '分配角色',
        type: 'C',
        parent: '/system/admin'
      }
    },
    {
      path: '/system/role/admin/:id',
      id: '/system/role/admin/:id',
      url: '../pages/system/role/admin',
      meta: {
        title: '分配管理员',
        type: 'C',
        parent: '/system/role'
      }
    },
    {
      path: '/order/customerMake/:id',
      url: '../pages/order/customerMake/detail',
      meta: {
        title: '客户定制详情',
        type: 'C',
        parent: '/order/customerMake'
      }
    },
    {
      path: '/order/orderManage/:id',
      url: '../pages/order/orderManage/detail',
      meta: {
        title: '订单详情',
        type: 'C',
        parent: '/order/orderManage'
      }
    },
    {
      path: '/order/chargeManage/:id',
      url: '../pages/order/editCharge',
      meta: {
        title: '运费',
        type: 'C',
        parent: '/order/chargeManage'
      }
    },
    {
      path: '/platform/carousel/:id',
      url: '../pages/platform/carousel/editForm',
      meta: {
        title: '编辑轮播图',
        type: 'C',
        parent: '/platform/carousel'
      }
    },
    {
      path: '/system/dict/:id',
      url: '../pages/system/dictValue',
      meta: {
        title: '字典数据',
        type: 'C',
        parent: '/system/dict'
      }
    },
    {
      path: '/commodity/commodityManage/:id',
      url: '../pages/commodity/editCommodity',
      meta: {
        title: '商品',
        type: 'C',
        parent: '/commodity/commodityManage'
      }
    },
    {
      path: '/member/manage/:id',
      url: '../pages/member/editMember',
      meta: {
        title: '编辑会员',
        type: 'C',
        parent: '/member/manage'
      }
    }
  ],
  routeList: [],
  menuList: [
    {
      key: '/home',
      label: '首页',
      icon: '#icon-shouye'
    },
    {
      key: '/system',
      label: '系统',
      icon: '#icon-xitong',
      children: [
        {
          key: '/system/admin',
          label: '管理员管理'
        },
        {
          key: '/system/role',
          label: '角色管理'
        },
        {
          key: '/system/resource',
          label: '资源管理',
          children: [
            {
              key: '/system/resource/images',
              label: '图片管理'
            },
            {
              key: '/system/resource/videos',
              label: '视频管理'
            }
          ]
        },
        {
          key: '/system/menu',
          label: '菜单管理'
        },
        {
          key: '/system/dict',
          label: '字典管理'
        },
        {
          key: '/system/log',
          label: '日志管理',
          children: [
            {
              key: '/system/log/login',
              label: '登录日志'
            },
            {
              key: '/system/log/operator',
              label: '操作日志'
            }
          ]
        }
      ]
    }
  ],
  changeRouteList: (data) =>
    set({
      routeList: _.cloneDeep(data)
    }),
  changeMenuList: (data) =>
    set({
      menuList: _.cloneDeep(data)
    }),
  async getRouteList() {
    const { code, data } = await getUserMenuApi();
    if (code === 200) {
      const routes = floatArray(data, 'children')
        .filter((item) => item.permissionType === '1')
        .map((item) => {
          return {
            path: item.permissionUrl,
            url: `../pages/${item.componentPath}`,
            meta: {
              title: item.menuName,
              type: 'C'
            }
          };
        });
      const menuList = formatMenuList(data);
      set((state) => ({
        routeList: state.staticRoute.concat(routes.filter((item) => item.path.startsWith('/'))),
        menuList: menuList.filter((item) => item.key.startsWith('/'))
      }));

      return Promise.resolve(get().routeList);
    }
  }
}));

function formatMenuList(data) {
  let result = [];
  data.forEach((item) => {
    result.push({
      key: item.permissionUrl,
      label: item.menuName,
      icon: item.permissionIcon ? item.permissionIcon : undefined,
      children: item.children && item.children.length ? formatMenuList(item.children) : undefined
    });
  });
  return result;
}
