import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Form, Input, ConfigProvider, Button } from 'antd';
import styled from 'styled-components';
import SvgIcon from '@/components/SvgIcon/index.jsx';
import { AES } from '@/utils/aes.js';
import { submitLoginApi } from '@/api/login.js';
import { useNavigate, useLocation } from 'react-router-dom';
import store from '@/store/permissions.js';
import { setToken } from '@/utils/token.js';
import { Vertify } from '@alex_xu/react-slider-vertify';
import CodeImage from '@/assets/images/validator/1.png?url';

const FormWrap = styled.div`
  margin-top: 30px;
  text-align: left;
`;
const SubmitButton = styled(Button)`
  margin-top: 10px;
  background: linear-gradient(90deg, #ff7f65 0%, #f61c29 100%);
  border-radius: 5px 5px 5px 5px;
  color: #fff;
  border: none;
  &:hover {
    background: linear-gradient(90deg, #ff7f65 0%, #f61c29 100%) !important;
    border-radius: 5px 5px 5px 5px;
    color: #fff !important;
    border: none;
  }
`;
const Tip = styled.div`
  margin-top: 16px;
  color: #999999;
  font-size: 12px;
  text-align: left;
`;
const CodeWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 8px;
`;
const Validator = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 3px 4px 1px rgba(120, 150, 183, 0.1);
`;
const LoginForm = () => {
  const [form] = Form.useForm();
  const [currentImage, setImage] = useState('');
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const nav = useNavigate();
  const { setUserInfo } = store();
  const mask = useRef(null);
  // const images = [];
  const images = import.meta.glob('../../../assets/images/validator/*', {
    eager: true
  });

  useEffect(() => {
    setImage(getRandomImage());
  }, []);

  //获取随机图片
  function getRandomImage() {
    const list = Object.entries(images).map((item) => item[1]);

    const iNow = Math.floor(Math.random() * list.length);

    return list[iNow].default;
  }
  //刷新验证图、
  function reloadImage() {
    setImage(getRandomImage());
  }

  //提交登录
  function startLogin() {
    form.validateFields().then(async () => {
      setOpen(true);
    });
  }
  async function submitEvent() {
    setOpen(false);
    const values = form.getFieldsValue();
    const params = {
      userName: values.userName,
      userPassword: AES.encrypt(values.password)
    };
    const { code, msg, data } = await submitLoginApi(params);
    if (code === 200) {
      setUserInfo(data);
      if (data.Authorization) {
        setToken(data.Authorization);
        if (location.search) {
          const searchParams = new URLSearchParams(window.location.search);
          if (searchParams.url) {
            nav(searchParams.url);
          } else {
            nav('/');
          }
        } else {
          nav('/');
        }
      }
    }
  }

  return (
    <FormWrap>
      <ConfigProvider
        theme={{
          token: {
            controlHeight: 44
          }
        }}
      >
        <Form form={form} colon={false} validateTrigger="onBlur">
          <Form.Item name="userName" rules={[{ required: true, message: '用户名不能为空' }]}>
            <Input prefix={<SvgIcon name="#icon-yonghu" />} />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: '用户名不能为空' }]}>
            <Input.Password prefix={<SvgIcon name="#icon-mima" />} />
          </Form.Item>
          <SubmitButton block onClick={startLogin}>
            登录
          </SubmitButton>
          <Tip>忘记密码请联系管理员进行重置</Tip>
        </Form>
      </ConfigProvider>

      {open && (
        <CodeWrap>
          <Validator>
            <Vertify
              width={320}
              height={160}
              visible={open}
              onSuccess={submitEvent}
              imgUrl={currentImage}
              onRefresh={reloadImage}
            />
          </Validator>
        </CodeWrap>
      )}
    </FormWrap>
  );
};

export default LoginForm;
