import Router from '@/router/index.jsx';
import React, { useEffect } from 'react';
import Loading from '@/components/loading/index.jsx';
import { useCommonData } from '@/store/common.js';

function MyApp() {
  const { loading } = useCommonData();

  return (
    <React.Fragment>
      <Router />
      {loading && <Loading />}
    </React.Fragment>
  );
}

export default MyApp;
