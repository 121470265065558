export function floatArray(data, key) {
  if (!key) {
    key = 'children';
  }
  let result = [];
  if (!data || !data.length) {
    return [];
  }
  data.forEach((item) => {
    result.push(item);
    if (item[key] && item[key].length) {
      const newData = item[key];
      result = result.concat(floatArray(newData, key));
    }
  });
  return result;
}

//下载文件流
export function downFile(res, fileName, type = 'application/msexcel;charset=UTF-8') {
  // 创建blob对象，解析流数据
  const blob = new Blob([res], {
    // 设置返回的文件类型
    // type:  表示下载文档类型
    type: type ? type : undefined
  });

  // 这里就是创建一个a标签，等下用来模拟点击事件
  const a = document.createElement('a');
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  // 根据解析后的blob对象创建URL 对象
  const herf = window.URL.createObjectURL(blob);
  // 下载链接
  a.href = herf;
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = fileName;
  document.body.appendChild(a);
  // 点击a标签，进行下载
  a.click();
  // 收尾工作，在内存中删除URL 对象
  document.body.removeChild(a);
  window.URL.revokeObjectURL(herf);
}
