import React, { useState } from 'react';
import styled from 'styled-components';
import LeftImage from '@/assets/images/login.png';
import loginLogo from '@/assets/images/loginLogo.png';
import LoginForm from './components/LoginForm.jsx';

const LoginPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${new URL('@/assets/images/loginBg.png', import.meta.url).href}) no-repeat 0 0;
  background-size: cover;
`;
const LoginImage = styled.img`
  width: 676px;
  height: 676px;
`;
const FormWrap = styled.div`
  width: 408px;
  height: 450px;
  background: #fff;
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
  padding: 24px;
`;
const LogoWrap = styled.div`
  margin-top: 16px;
  text-align: center;
  position: relative;
  img {
    width: 72px;
    height: 54px;
  }
`;
const Title = styled.div`
  margin-top: 24px;
  line-height: 1;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
`;

const index = () => {
  return (
    <LoginPage>
      <LoginImage src={LeftImage} />
      <FormWrap>
        <LogoWrap>
          <img src={loginLogo} alt="赖茅世" />
          <Title>欢迎登录后台管理系统</Title>
          <LoginForm />
        </LogoWrap>
      </FormWrap>
    </LoginPage>
  );
};

export default index;
