import { Layout, ConfigProvider, App, message } from 'antd';
import { Outlet } from 'react-router-dom';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import React, { useEffect } from 'react';
import variables from '@/assets/css/variables.js';
import AppHeader from '@/layout/components/Header.jsx';
import AppMenu from '@/layout/components/AppMenu.jsx';
import CommonNav from './components/CommonNav.jsx';
import { userRouterData } from '@/store/router.js';

export default function AppLayout() {
  const [api, contextHolder] = message.useMessage();
  const { getRouteList } = userRouterData();
  useEffect(() => {
    if (!window.message) {
      window.message = api;
    }
    getRouteList();
  }, []);

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          ...variables
        },
        components: {
          Button: {
            algorithm: true, // 启用算法
            borderRadius: 5
          },
          Input: {
            algorithm: true // 启用算法
          },
          Tooltip: {
            algorithm: true, // 启用算法
            paddingXS: 0,
            colorBgSpotlight: '#fff'
          }
        }
      }}
    >
      <App style={{ height: '100%' }}>
        <Layout style={{ height: '100%' }}>
          <Layout.Header className="app-header">
            <AppHeader />
          </Layout.Header>
          <Layout>
            <Layout.Sider className="app-slide" width={240}>
              <AppMenu />
            </Layout.Sider>
            <Layout.Content>
              <CommonNav />
              <div className="router-wrap">
                <Outlet />
              </div>
            </Layout.Content>
          </Layout>
        </Layout>
        {contextHolder}
      </App>
    </ConfigProvider>
  );
}
