import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import 'virtual:svg-icons-register';
import 'antd/dist/reset.css';
import './assets/css/main.less';
import '//at.alicdn.com/t/c/font_4432343_m9mwtcnttci.js';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
