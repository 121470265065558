import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';

export const useCommonData = create((set, get) => ({
  loading: false,
  setLoading: (value) =>
    set({
      loading: value
    })
}));
