import React, { useEffect, useMemo, useState } from 'react';
import { userRouterData } from '@/store/router.js';
import Loading from '@/components/loading/index.jsx';
import { useRoutes, Navigate } from 'react-router-dom';
import AppLayout from '@/layout/index.jsx';
import Login from '@/pages/login/index.jsx';
import { reloadRoutes } from '@/events/menu.js';
import { useLocation } from 'react-router-dom';
import { setToken } from '@/utils/token.js';

export default function Routes() {
  const Elements = import.meta.glob('../pages/**/*.jsx');
  //userRouterData.persist.clearStorage();
  const { routeList, getRouteList } = userRouterData();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith('/login')) {
      getRouteList();
    }
    if (location.search) {
      const queryList = new URLSearchParams(window.location.search);
      if (queryList.token) {
        setToken(queryList.token);
      }
    }
  }, []);
  useEffect(() => {
    reloadRoutes.addListener('reload', getRouteList);
    return () => {
      reloadRoutes.removeListener('reload', getRouteList);
    };
  });
  const routerList = useMemo(() => {
    const list = routeList.map((item) => {
      let Element = Elements[item.url + '/index.jsx'] ? React.lazy(Elements[item.url + '/index.jsx']) : '';
      return {
        path: item.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <Element />
          </React.Suspense>
        )
      };
    });
    return [
      {
        path: '/',
        element: <AppLayout />,
        children: [
          ...list,
          {
            path: '',
            element: <Navigate to={'/home'} />
          }
        ]
      },
      {
        path: '/login',
        element: <Login />
      }
    ];
  }, [routeList]);

  return <React.Suspense fallback={<Loading />}>{useRoutes(routerList)}</React.Suspense>;
}
