import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import { userRouterData } from '@/store/router.js';
import styled from 'styled-components';
import SvgIcon from '@/components/SvgIcon/index.jsx';
import { floatArray } from '@/utils/tools.js';
import { useDeepCompareEffect } from 'ahooks';

const MenuWrap = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  position: relative;
  z-index: 11;
  box-shadow: 4px 2px 4px 1px rgba(120, 150, 183, 0.1);
`;
const Icon = styled(SvgIcon)`
  font-size: 16px !important;
  position: relative;
  top: 2px;
`;
function AppMenu() {
  const location = useLocation();
  const params = useParams();
  const nav = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const { menuList, routeList } = userRouterData();
  const floatMenu = React.useMemo(() => {
    const list = floatArray(menuList, 'children');
    list.unshift({
      key: '/home'
    });
    return list;
  }, [menuList]);
  const items = React.useMemo(() => {
    let menus = menuList.map((item) => {
      return {
        key: item.key,
        label: item.label,
        children: item.children,
        icon: <Icon name={item.icon}></Icon>
      };
    });
    menus.unshift({
      key: '/home',
      label: '首页',
      icon: <Icon name={'#icon-shouye'}></Icon>
    });
    return menus;
  }, [menuList]);

  useEffect(() => {}, [items]);

  //加载和路由变换时改变选中的值
  useDeepCompareEffect(() => {
    let values = findMenu();
    setSelectedKeys(values);
  }, [location.pathname]);

  //加载和路由变换时改变open的值
  useDeepCompareEffect(() => {
    let keys = findSubMenu();
    setOpenKeys(keys);
  }, [selectedKeys]);
  //
  //选择菜单
  function selectedMenuItem(val) {
    nav(val.key);
  }

  //查找目录收缩
  function findSubMenu(path) {
    let result = [];
    let pathname = path ? path : selectedKeys[0];

    if (!pathname) {
      return result;
    }
    let current = floatMenu.find((item) => item.children && item.children.some((child) => child.key === pathname));

    if (current) {
      result.push(current.key);
      if (floatMenu.some((item) => item.children && item.children.some((child) => child.key === current.key))) {
        result = findSubMenu(current.key).concat(result);
      }
      return result;
    }
    return result;
  }

  //查找菜单以及菜单的父节点
  function findMenu() {
    let path = location.pathname;
    if (path === '/') {
      return ['/home'];
    }
    if (params.id) {
      path = path.replace(new RegExp('/' + params.id), '/:id');
    }
    let current = floatMenu.find((item) => item.key === path);
    if (current) {
      return [current.key];
    } else {
      let route = routeList.find((item) => item.path === path);
      if (route && route.meta.parent) {
        let menu = floatMenu.find((item) => item.key === route.meta.parent);
        return menu ? [menu.key] : [];
      }
      return [];
    }
  }

  //展开和收缩事件
  function changeOpenKeys(keys) {
    setOpenKeys(keys);
  }
  return (
    <MenuWrap>
      <Menu
        items={items}
        selectedKeys={selectedKeys}
        onClick={selectedMenuItem}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={changeOpenKeys}
      />
    </MenuWrap>
  );
}

export default AppMenu;
