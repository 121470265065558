import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Logo from '@/assets/images/logo.png';
import HeadUser from '@/assets/images/header/user.png';
import HeadClose from '@/assets/images/header/close.png';
import store from '@/store/permissions.js';
import { setToken } from '@/utils/token.js';

const HeaderWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LogoImage = styled.img`
  width: 117px;
  height: 40px;
`;
const UserWrap = styled.div`
  display: flex;
  align-items: center;
  .name {
    color: #fff;
  }
`;
const IconBox = styled.div`
  color: #fff;
  margin-left: 5px;
`;
const UserTip = styled(Tooltip)``;
const ContentWrap = styled.div`
  background: #fff;
  width: 204px;
  height: 204px;
  padding: 15px;
`;
const ButtonItem = styled.div`
  height: 72px;
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  color: #343434;
  cursor: pointer;
  &:hover {
    background: #f6f6f6;
  }
  &:last-child {
    margin-top: 30px;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
`;

//弹窗
const TooltipContent = () => {
  const { setUserInfo } = store();
  const nav = useNavigate();

  function goProfile() {
    nav('/account-info');
  }

  //退出登录
  function logout() {
    setUserInfo(null);
    setToken('');
    nav('/login');
  }
  return (
    <ContentWrap>
      <ButtonItem className="active" onClick={goProfile}>
        <img src={HeadUser} alt="头像" />
        <span>帐号信息</span>
      </ButtonItem>
      <ButtonItem className="active" onClick={logout}>
        <img src={HeadClose} alt="头像" />
        <span>退出登录</span>
      </ButtonItem>
    </ContentWrap>
  );
};
function AppHeader() {
  const [open, setOpen] = React.useState(false);
  const { userInfo } = store();
  const userName = useMemo(() => {
    return userInfo && userInfo.userNickname ? userInfo.userNickname : '用户名称';
  }, [userInfo]);

  //打开关闭弹窗
  function changeTipOpen(val) {
    setOpen(val);
  }
  return (
    <HeaderWrap>
      <LogoImage src={Logo} />
      <UserWrap>
        <span className="name">{userName}</span>
        <UserTip title={<TooltipContent />} open={open} onOpenChange={changeTipOpen}>
          <IconBox>
            <DownOutlined />
          </IconBox>
        </UserTip>
      </UserWrap>
    </HeaderWrap>
  );
}

export default AppHeader;
